@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --theme-blue: #34c0f1;
  --theme-dark-blue: #003b66;
  /* --theme-dark-blue: #234c9d; */
  --theme-white: white;
  --theme-black: black;
  --theme-white-transparent: rgba(255, 255, 255, 0.624);
  --theme-dark-blue-transparent: #234c9db6;
  --theme-blue-transparent: #34bff181;
  --theme-blue-hight-transparent: #34bff127;
  --theme-grey: #f2f2f2;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.myborder {
  border: 5px solid red;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.display-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-flex-align-items-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.display-flex-align-items-top-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.display-flex-align-items-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.color-theme-blue {
  color: var(--theme-blue);
}
.color-theme-grey {
  color: var(--theme-grey);
}
.bg-theme-blue {
  background-color: var(--theme-blue);
}
.color-theme-dark-blue {
  color: var(--theme-dark-blue);
}
.bg-theme-dark-blue {
  background-color: var(--theme-dark-blue);
}
.color-theme-white {
  color: var(--theme-white);
}
.bg-theme-white {
  background-color: var(--theme-white);
}
.bg-theme-grey {
  background-color: var(--theme-grey);
}
.bg-theme-blue-transparent {
  background-color: var(--theme-blue-hight-transparent);
}
.fs-smallest-css {
  font-size: 0.4em;
}
.fs-smaller-css {
  font-size: 0.6em;
}
.fs-slight-small-css {
  font-size: 0.9em;
}
.fs-small-css {
  font-size: 0.6em;
}
.fs-medium-css {
  font-size: 1.2em;
}
.fs-large-css {
  font-size: 1.6em;
}
.fs-larger-css {
  font-size: 2.5em;
}
.fs-largest-css {
  font-size: 4em;
}
.fs-medium-small-css
{
  font-size: 0.8em;
}
.line-height
{
  line-height: 25px;
}
.height-30vh
{
  height: 30vh;
  object-fit: cover;
}
.btn-navbar {
  margin: 0px;
  background-color: transparent;
  padding: 10px 10px;
  outline: none;
  border: none;
  color: var(--theme-black);
  height: 10vh;
  font-size: 0.8em;
}
.w-150 {
  width: 17rem;
}
.btn-navbar-internal {
  color: var(--theme-black);
  background-color: transparent;
  font-size: 0.9em;
  border: none;
  outline: none;
  padding: 5px 15px;
  text-align: left;
  transition: all 0.6s;
}
.btn-navbar-internal-converse {
  color: var(--theme-dark-blueblue);
  background-color: transparent;
  font-size: 0.9em;
  border: none;
  outline: none;
  padding: 5px 15px;
  text-align: left;
  transition: all 0.6s;
}
.btn-navbar-internal:hover,
.btn-navbar-internal:focus {
  color: var(--theme-blue);
}
.btn-navbar-internal-converse:hover,
.btn-navbar-internal-converse:focus {
  color: var(--theme-blue);
}
.btn-navbar-internal-dropdown {
  color: var(--theme-blue);
  background-color: transparent;
  font-size: 1.5em;
  border: none;
  outline: none;
  padding: 0px 15px;
  text-align: left;
  transition: all 0.6s;
}
.btn-navbar-internal-dropdown:hover,
.btn-navbar-internal-dropdown:focus {
  color: var(--theme-dark-blue);
}
.btn-navbar-underline,
.btn-navbar-underline:not(.hover) {
  height: 3px;
  font-size: 1.1em;
  border: none;
  outline: none;
  background-color: var(--theme-blue);
  transform: scale(0);
  transform-origin: center;
  transition: all 0.6s;
}
.btn-navbar:hover .btn-navbar-underline {
  transform: scale(1);
  transition: all 0.4s;
}
.btn-special {
  background-color: var(--theme-blue);
  color: var(--theme-white);
  padding: 15px 10px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.4s;
}
.btn-special:hover,
.btn-special:focus {
  background-color: var(--theme-white);
  color: var(--theme-blue);
  padding: 15px 10px;
  border: 1px solid var(--theme-blue);
}
.btn-special-converse {
  background-color: var(--theme-white);
  color: var(--theme-dark-blue);
  padding: 15px 0px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.4s;
}
.btn-special-converse:hover,
.btn-special-converse:focus {
  background-color: var(--theme-dark-blue);
  color: var(--theme-white);
  padding: 15px 25px;
  border: 1px solid var(--theme-dark-blue);
}
.input-field {
  padding: 15px 25px;
  background-color: transparent;
  border: 1px solid var(--theme-blue);
  color: var(--theme-white);
  font-weight: 600;
}
.input-field:focus {
  outline: none;
  box-shadow: 5px 5px 15px var(--theme-dark-blue);
}
.input-field::placeholder {
  color: var(--theme-white);
  font-weight: 600;
}
.theme-blue-shadow {
  box-shadow: 5px 2px 25px var(--theme-dark-blue);
}
.navbar-shadow {
  box-shadow: 5px 2px 25px var(--theme-dark-blue);
  position: sticky;
  top: 0px;
}
.mainpage-div-1 {
  background-image: url("https://dvps.in.net/dvps_images/k-series.jpg");
  background-size: cover;
  background-position: center;
  width: 60vw;
  position: absolute;
  margin-left: auto;
  top: 0px;
  z-index: -1;
}
.mainpage-small-bg-image {
  background-image: url("https://dvps.in.net/dvps_images/k-series.jpg");
  background-size: cover;
}
.mainpage-div-2-blue-wash {
  width: 100vw;
  background-color: var(--theme-dark-blue-transparent);
  z-index: 0;
  position: relative;
  top: 0px;
  left: -45vw;
  transform: skewX(-30deg);
}
.bg-black-transparent {
  background-color: var(--theme-dark-blue-transparent);
}
.mainpage-div-5-blue-wash {
  width: 100vw;
  background-color: var(--theme-dark-blue-transparent);
  position: absolute;
  top: 0px;
  left: -35vw;
  transform: skewX(-30deg);
}
.mainpage-div-3-blue-wash {
  width: 100vw;
  background-color: var(--theme-dark-blue-transparent);
  z-index: 0;
  position: absolute;
  /* top: 0px; */
  bottom: -50vh;
  left: -35vw;
  transform: skewX(-30deg);
}
.mainpage-div-4-blue-wash {
  width: 100vw;
  background-color: var(--theme-dark-blue-transparent);
  z-index: 0;
  position: absolute;
  bottom: -50vh;
  left: -50vw;
  transform: skewX(-30deg);
}
.position-relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.mainpage-div-6-content {
  width: 55vw;
  background-color: transparent;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 10vw;
}
.line {
  background-color: var(--theme-dark-blue);
  height: 7px;
  width: 30%;
}
.line-black {
  background-color: black;
  height: 7px;
  width: 30%;
}
.line-theme-blue {
  background-color: var(--theme-blue);
  height: 3px;
  width: 30%;
}
@media (min-width: 1300px) and (max-width: 3000px) {
  .mainpage-div-height {
    height: 60vh;
  }
  /* .products-btn-div
  {
    margin-top: 0px;
  } */
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .mainpage-div-height {
    height: 70vh;
  }
  /* .products-btn-div
  {
    margin-top: 0px;
  } */
}

@media (min-width: 900px) and (max-width: 1100px) {
  .mainpage-div-height {
    height: 60vh;
  }
  /* .products-btn-div
  {
    margin-top: 0px;
  } */
}

@media (min-width: 700px) and (max-width: 900px) {
  .mainpage-div-height {
    height: 50vh;
  }
  /* .products-btn-div
  {
    margin-top: 3px;
  } */
}
.img-dots-7x7-aboutus {
  position: absolute;
  top: 2.5vh;
  left: -1.5vw;
  z-index: -1;
  width: 40%;
}
.img-dots-4x4-aboutus {
  position: absolute;
  bottom: 3vh;
  right: -2vw;
  z-index: -1;
  width: 25%;
}
.img-dots-7x7-aboutus-page {
  position: absolute;
  top: -1vh;
  right: -3vw;
  z-index: -1;
  width: 40%;
}
.img-dots-4x4-aboutus-page {
  position: absolute;
  bottom: -3vh;
  left: -1vw;
  z-index: -1;
  width: 25%;
}
.btn-toggle {
  background: transparent;
  padding: 15px 35px;
  height: 70px;
  width: 250px;
  color: var(--theme-dark-blue);
  border: 1px solid transparent;
  outline: none;
  transition: all 0.6s;
  transform-origin: left;
  position: relative;
  z-index: 2;
}
.btn-toggle:hover {
  color: var(--theme-blue);
}
.btn-toggle:focus {
  color: var(--theme-white);
}
.btn-toggle-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  background: var(--theme-dark-blue);
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  transform-origin: left;
  transform: scaleX(0);
  z-index: -1;
}
.btn-toggle:focus .btn-toggle-bg {
  transform: scaleX(1);
}
.btn-all-services {
  background-color: transparent;
  color: var(--theme-dark-blue);
  padding: 0px;
  border: none;
  outline: none;
  transition: all 0.6s;
  font-size: 1.2em;
  font-weight: 700;
}
.btn-all-services:hover,
.btn-all-services:focus {
  color: var(--theme-blue);
}

.service-card {
  background-color: var(--theme-white);
  min-height: 30vh;
  perspective: 1000px; /* Provides the 3D space */
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d; /* Keeps 3D transformations */
  cursor: pointer;
}
.service-card:hover {
  transform: rotateY(180deg); /* Rotates the card on hover */
}
.service-card-svg {
  height: 250px;
  width: 300px;
  color: var(--theme-blue);
}
.scale-2{
  transform: scale(2);
}
.svg-service {
  /* transform: scale(1.5); */
  /* height: 250px; */
  /* width: 100%; */
  object-fit: cover;
  /* fill: var(--theme-blue); */
  /* margin-left: -10%; */
}
.service-card-front,
.service-card-back {
  position: absolute;
  width: 100%;
  min-height: 100%;
  backface-visibility: hidden; /* Hides the back side when not rotated */
  padding: 10px 0px;
}
.service-card-front {
  background-color: var(--theme-white);
}

.service-card-back {
  background-color: var(--theme-blue);
  color: black;
  transform: rotateY(180deg); /* Initially hides the back side */
}
.btn-special-black {
  background-color: var(--theme-white);
  color: black;
  padding: 15px 25px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.4s;
}
.btn-special-black:hover,
.btn-special-black:focus {
  background-color: black;
  color: var(--theme-white);
  padding: 15px 25px;
  border: 1px solid black;
}
.btn-special-black-converse {
  background-color: black;
  color: var(--theme-white);
  padding: 15px 25px;
  border: 1px solid transparent;
  outline: none;
  transition: all 0.4s;
}
.btn-special-black-converse:hover,
.btn-special-black-converse:focus {
  background-color: var(--theme-white);
  color: black;
  padding: 15px 25px;
  border: 1px solid var(--theme-white);
}
.service-card-number {
  font-size: 3em;
  font-weight: 900;
  color: var(--theme-grey);
  text-shadow: 2px 2px 4px var(--theme-dark-blue);
  position: absolute;
  bottom: -10%;
  right: -5%;
}
.service-card-number-product {
  font-size: 1em;
  font-weight: 900;
  color: var(--theme-grey);
  text-shadow: 2px 2px 4px var(--theme-dark-blue);
  position: absolute;
  bottom: -2%;
  right: 7%;
}
.our-product-image {
  height: 45vh;
  object-fit: cover;
  transition: all 0.6s;
  z-index: -2;
}
.our-product:hover .our-product-image {
  transform: scale(1.1);
}
.btn-social-media-icons {
  height: 50px;
  width: 50px;
  background-color: var(--theme-blue);
  color: var(--theme-white);
  border: none;
  outline: none;
  z-index: -1;
  transform: translateY(-400%);
  transition: all 0.6s;
  opacity: 0;
}
.our-product:hover .social-media-icon-1 {
  opacity: 1;
  transition-delay: 0.2s;
  transform: translateY(-600%);
}
.our-product:hover .social-media-icon-2 {
  opacity: 1;
  transition-delay: 0.3s;
  transform: translateY(-600%);
}
.our-product:hover .social-media-icon-3 {
  opacity: 1;
  transition-delay: 0.4s;
  transform: translateY(-600%);
}
.dots-4x4-price-upper {
  position: absolute;
  top: -5vh;
  left: -5vw;
  z-index: 0;
  /* transform: rotate(180deg); */
  width: 15%;
}
.dots-4x4-price-lower {
  position: absolute;
  bottom: -5vh;
  right: center;
  z-index: 0;
  /* transform: rotate(180deg); */
  width: 15%;
}
.z-index-1 {
  z-index: 1;
}
.price-image-section {
  position: absolute;
  top: 0px;
}
.what-depends-on-price-content {
  position: absolute;
  /* top: 20vh; */
  z-index: 1;
}
.input-field-form {
  background-color: var(--theme-grey);
  padding: 15px 25px;
  font-size: 1.1em;
  color: black;
  outline: none;
  border: none;
  font-weight: 500;
}
.input-field-form::placeholder,
.input-field-form-enquiry::placeholder {
  font-weight: 400;
}
.input-field-form-enquiry {
  background-color: var(--theme-white);
  padding: 10px 25px;
  font-size: 0.8em;
  color: black;
  outline: none;
  border: none;
  font-weight: 500;
}
.call-form {
  z-index: 4;
  transform: translateY(-60%);
}
.testimonial {
  position: relative;
}

@media (min-width: 900px) and (max-width: 5000px) {
  .testimonial-content {
    position: absolute;
    top: 50px;
    right: 0px;
  }
  .margin-top-30vh {
    margin-top: 100%;
  }
  .margin-top-20vh {
    margin-top: 50%;
  }
  .margin-top-10vh {
    margin-top: 0vh;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .testimonial-content {
    position: static;
    /* top: 00px;
    right: 0px; */
  }
  .margin-top-30vh {
    margin-top: 50px;
  }
  .margin-top-20vh {
    margin-top: 50px;
  }
  .margin-top-10vh {
    margin-top: 50px;
  }
}
.overflow-x-hidden {
  overflow-x: hidden;
}

/* TestimonialSlider.css */
.testimonial-slider {
  width: 100%;
  overflow: scroll;
  position: relative;
}

.testimonial-container {
  display: flex;
  transition: transform 1s ease-in-out;
  position: relative;
}

.testimonial-css {
  min-width: 100%;
  box-sizing: border-box;
  padding: 20px;
  /* background-color: #f5f5f5; */
  text-align: left;
  font-size: 0.9em;
  border: none;
}

/* Dynamic classes to control the sliding effect */
.index-0 {
  transform: translateX(0%);
}
.index-1 {
  transform: translateX(-100%);
}
.index-2 {
  transform: translateX(-200%);
}
.index-3 {
  transform: translateX(-300%);
}

.testimonials-7x7 {
  position: absolute;
  top: 5vh;
  left: 0px;
  width: 15%;
  z-index: -1;
}
.testimonials-4x4 {
  position: absolute;
  bottom: 10vh;
  right: 0px;
  width: 10%;
  z-index: -1;
}
.underline-on-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}
.card-shadow {
  box-shadow: 5px 5px 10px var(--theme-dark-blue-transparent);
  transition: all 0.4s;
}
.card-shadow:hover {
  box-shadow: 10px 10px 25px var(--theme-dark-blue-transparent);
}
.contact-us-card {
  border-left: 1px solid var(--theme-blue-transparent);
}
.footer-logo {
  /* height: 150px; */
  width: 30%;
  object-fit: cover;
}
.btn-footer {
  background-color: transparent;
  color: var(--theme-white);
  font-size: 0.9em;
  padding: 0px;
  font-weight: 600;
  border: none;
  outline: none;
  width: auto;
  margin-right: 35px;
  transition: all 0.6s;
}
.btn-footer:hover,
.btn-footer:focus {
  color: var(--theme-blue);
}

.btn-footer-icon {
  background-color: transparent;
  color: var(--theme-white);
  font-size: 1.3em;
  padding: 0px;
  font-weight: 600;
  border: none;
  outline: none;
  width: auto;
  margin-right: 25px;
  transition: all 0.6s;
}
.w-60 {
  width: 60%;
}
.btn-footer-icon:hover,
.btn-footer-icon:focus {
  color: var(--theme-blue);
}
.border-top-theme-blue {
  border-top: 1px solid var(--theme-blue-transparent);
}
.height-50vh {
  height: 50vh;
}
.height-60vh {
  min-height: 20vh;
}
.header-bg-image {
  background-image: url("https://dvps.in.net/dvps_images/header-bg-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
}
.header-blue-wash {
  /* background: linear-gradient(to right , var(--theme-dark-blue-transparent), var(--theme-grey)); */
  background-color: var(--theme-dark-blue-transparent);
  opacity: 0.6;
  position: absolute;
  top: 0px;
  z-index: -1;
}
.header-content {
  position: absolute;
  top: 0px;
  z-index: 1;
  height: 100%;
}
.btn-play {
  color: black;
  background-color: transparent;
  font-size: 1.2em;
  height: 70px;
  width: 70px;
  border: 1px solid var(--theme-blue);
  outline: none;
  transition: all 0.6s;
}
.btn-play:hover,
.btn-play:focus {
  color: var(--theme-white);
  background-color: black;
}
.sequence-card {
  background-color: var(--theme-white);
  position: relative;
  transition: all 0.6s;
  color: var(--theme-dark-blue);
  /* overflow-x: hidden; */
  /* z-index: 1; */
}
.sequence-card-number {
  background-color: transparent;
  position: absolute;
  top: -100px;
  font-size: 7em;
  font-weight: 900;
  color: var(--theme-grey);
  text-shadow: 5px 5px 6px var(--theme-dark-blue);
  z-index: 2;
  transition: all 0.6s;
}

.sequence-card:hover,
.sequence-card:focus {
  background-color: var(--theme-blue);
  color: var(--theme-white);
}
.sequence-card:hover .sequence-card-number {
  text-shadow: -5px -5px 6px var(--theme-dark-blue);
}
.sequence-card:hover .sequence-card-square {
  color: var(--theme-white);
}
.sequence-card-square {
  transition: all 0.6s;
  color: var(--theme-blue-transparent);
}
.sequence-card-square-1 {
  position: absolute;
  top: 3vh;
  right: 2vw;
}
.sequence-card-square-3 {
  position: absolute;
  top: 20vh;
  left: 0.5vw;
}
.sequence-card-square-2 {
  position: absolute;
  top: 20vh;
  right: 0px;
}
.contact-us-address-icon {
  height: 80px;
  width: 80px;
  background-color: var(--theme-blue);
  color: black;
  border: none;
  outline: none;
  font-size: 1.5em;
}

.service-card-product {
  background-color: var(--theme-white);
  height: 80vh;
  perspective: 1000px; /* Provides the 3D space */
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d; /* Keeps 3D transformations */
  cursor: pointer;
}
.service-card-product:hover {
  transform: rotateY(180deg); /* Rotates the card on hover */
}
.service-card-product-svg {
  height: 100px;
  width: 100px;
  color: var(--theme-blue);
}
.mainpage-svg {
  fill: var(--theme-white);
}
.service-card-product-front,
.service-card-product-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hides the back side when not rotated */
  /* padding: 30px 0px; */
}
.product-spe-card {
  background-color: var(--theme-blue);
  height: 80vh;
}
.service-card-product-front {
  background-color: var(--theme-white);
}

.service-card-product-back {
  background-color: var(--theme-blue);
  color: black;
  transform: rotateY(180deg); /* Initially hides the back side */
}
.w-20 {
  width: 16%;
  height: 10vh;
}
.service-btn {
  position: absolute;
  top: -22px;
}
.service-dropdown {
  position: absolute;
  top: 10px;
  background-color: white;
  transform-origin: top;
  transition: all 0.6s;
  width: 200px;
  border-right: 3px solid var(--theme-blue);
  border-left: 3px solid var(--theme-blue);
  border-bottom: 3px solid var(--theme-blue);
}
.scale-y-0 {
  transform: scaleY(0);
  transition: all 0.6s;
}
.scale-y-1 {
  transform: scaleY(1);
  transition: all 0.6s;
}
.z-index-99 {
  z-index: 99;
  position: sticky;
  top: 0px;
}
.rotate-0 {
  transform: rotate(0deg);
  transition: all 0.6s;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: all 0.6s;
}
.cursor-pointer {
  cursor: pointer;
}
.product-image {
  object-fit: cover;
  height: 200px;
  width: 200px;
}
.sticky-btn-container {
  position: fixed;
  bottom: 40px;
  right: 0px;
  z-index: 4;
}
.nav-btn-div-height {
  height: 10vh;
}
.myborder-transparent {
  border: 5px solid transparent;
}
.products-dropdown {
  transform-origin: top;
  border-bottom: 2px solid var(--theme-blue);
  border-left: 2px solid var(--theme-blue);
  border-right: 2px solid var(--theme-blue);
}
.input-field-checkbox {
  height: 20px;
  width: 20px;
}
.form {
  transform-origin: top;
}
.partner {
  height: 15vh;
  object-fit: contain;
}
.partner-rounded {
  border-radius: 20%;
}
.partner-card {
  background-color: transparent;
}
.partner-card:hover,
.partner-card:focus {
  /* background-color: var(--theme-blue); */
  /* transition: all 1s; */
  /* border-radius: 20%; */
}
.partner-card:not(:hover) {
  background-color: transparent;
  transition: all 1s;
  border-radius: 0%;
}
.border-theme-dark-blue {
  border: 1px solid var(--theme-blue);
}
@media (min-width: 900px) and (max-width: 5000px) {
  .display-on-large-screen {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .display-on-large-screen-align-items-top
  {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
  .display-on-large-screen-flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .display-on-small-screen {
    display: none;
  }
  .display-on-small-screen-center {
    display: none;
  }
  .position-sticky-top-left {
    position: sticky;
    top: 30vh;
    left: 0px;
  }
  .position-sticky-top-right {
    position: sticky;
    top: 30vh;
    right: 0px;
  }
  .position-sticky-top {
    position: sticky;
    top: 25vh;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .display-on-large-screen {
    display: none;
  }
  .display-on-large-screen-align-items-top
  {
    display: none;
  }
  .display-on-large-screen-flex-end {
    display: none;
  }
  .display-on-small-screen {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .display-on-small-screen-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .position-sticky-top-left {
    position: static;
  }
  .position-sticky-top-right {
    position: static;
  }
  .position-sticky-top {
    position: static;
  }
}
.btn-navbar-toggle {
  background-color: var(--theme-white);
  color: var(--theme-blue);
  border: 1px solid var(--theme-blue);
  outline: none;
  padding: 10px 15px;
  font-size: 1em;
}
.btn-navbar-toggle:hover,
.btn-navbar-toggle:focus {
  background-color: var(--theme-blue);
  color: var(--theme-white);
  border: 1px solid var(--theme-blue);
  outline: none;
}
.small-screen-navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  transform-origin: left;
  transition: all 0.6s;
  background-color: var(--theme-white);
  z-index: 99;
}
.scale-x-0 {
  transform: scaleX(0);
}
.scale-x-1 {
  transform: scaleX(1);
}
.height-100vh {
  height: 100vh;
}
.btn-navbar-product,
.select-product {
  background-color: white;
  color: var(--theme-dark-blue);
  padding: 15px 10px;
  border: none;
  outline: none;
  transition: all 0.6s;
  text-align: left;
  /* font-size: 0.8em; */
  border: 0.5px solid #003c6610;
}

.border-left-grey {
  border-left: 1px solid var(--theme-grey);
}
.btn-navbar-product:focus {
  background-color: var(--theme-dark-blue);
  color: var(--theme-white);
  border: 0.5px solid transparent;
}

.btn-header {
  background-color: transparent;
  color: var(--theme-dark-blue);
  border: none;
  outline: none;
  transition: all 0.6s;
  width: auto;
}
.btn-header:hover,
.btn-header:focus {
  background-color: transparent;
  color: var(--theme-blue);
  border: none;
  outline: none;
}
.height-30px {
  height: 15px;
  /* color: red; */
}
.btn-special:disabled {
  background-color: var(--theme-blue-hight-transparent);
  color: var(--theme-dark-blue-transparent);
}
.btn-link-css
{
  color: rgb(170, 167, 167);
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  text-underline-offset: 5px;
  transition: all 0.6s;
}
.btn-link-css:hover , .btn-link-css:focus
{
  color: var(--theme-dark-blue);
}
.border-bottom-grey
{
  border: 1px solid rgb(200, 198, 198);
}
.p-carousel-prev,
.p-carousel-next {
  display: none !important;
}
.height-40vh
{
  height: 25vh;
}
.display-flex-align-items-top-end
{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.display-flex-align-items-top-between
{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}